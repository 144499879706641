<template>
  <div>
    <b-card v-if="userData.userData.user">
      <b-row>
        <b-col cols="12" class="d-flex justify-content-between flex-column">
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="userData.userData.user.avatar"
              :text="avatarText(userData.userData.user.fullName)"
              size="104px"
              rounded
            />
          </div>
        </b-col>
        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 w-100">
            <tr>
              <th class="pb-1">
                <feather-icon icon="UserIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Login Name") }}</span>
              </th>
              <td class="pb-1">
                {{ userData.userData.user.username }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="SendIcon" class="mr-75" />
                <span class="font-weight-bold">Email</span>
              </th>
              <td
                class="pb-1"
                style="display: flex; justify-content: flex-start; gap: 12px"
              >
                <b-overlay
                  :show="loadingShowEmail"
                  variant="transparent"
                  no-wrap
                />
                {{ this.userEmail }}
                <span
                  class="cursor-pointer"
                  v-if="$can('view', 'useremail')"
                  @click.prevent="toggleShow('email')"
                >
                  <feather-icon :icon="toggleIcon('email')" />
                </span>
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Phone") }}</span>
              </th>
              <td
                class="pb-1"
                style="display: flex; justify-content: flex-start; gap: 12px"
              >
                <b-overlay
                  :show="loadingShowPhone"
                  variant="transparent"
                  no-wrap
                />
                {{ this.userPhone }}
                <span
                  class="cursor-pointer"
                  v-if="$can('view', 'userphone')"
                  @click.prevent="toggleShow('phone')"
                >
                  <feather-icon :icon="toggleIcon('phone')" />
                </span>
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Status") }}</span>
              </th>
              <td class="pb-1 text-capitalize">
                <div :class="`text-capitalize align-items-center d-flex`">
                  <b-link
                    v-if="$can('update', 'userstatus')"
                    :class="`text-${
                      resolveUserStatus(
                        statusUpdateInfo == -1
                          ? userData.userData.user.stat
                          : statusUpdateInfo
                      ).variant
                    }`"
                    @click="showModalUpdateStatus()"
                  >
                    <span
                      :class="`dot bg-${
                        resolveUserStatus(
                          statusUpdateInfo == -1
                            ? userData.userData.user.stat
                            : statusUpdateInfo
                        ).variant
                      }`"
                    />
                    {{
                      resolveUserStatus(
                        statusUpdateInfo == -1
                          ? userData.userData.user.stat
                          : statusUpdateInfo
                      ).label
                    }}
                  </b-link>
                  <b-link
                    v-else
                    :class="`text-${
                      resolveUserStatus(
                        statusUpdateInfo == -1
                          ? userData.userData.user.stat
                          : statusUpdateInfo
                      ).variant
                    }`"
                  >
                    <span
                      :class="`dot bg-${
                        resolveUserStatus(
                          statusUpdateInfo == -1
                            ? userData.userData.user.stat
                            : statusUpdateInfo
                        ).variant
                      }`"
                    />
                    {{
                      resolveUserStatus(
                        statusUpdateInfo == -1
                          ? userData.userData.user.stat
                          : statusUpdateInfo
                      ).label
                    }}
                  </b-link>
                </div>
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="PhoneIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Call Center") }}</span>
              </th>
              <td
                class="pb-1"
                style="display: flex; justify-content: flex-start; gap: 12px"
              >
                <b-link>
                  <span
                    v-if="$can('read', 'callcenter')"
                    @click="toCallCenter()"
                    class="text-success"
                  >
                    Call
                  </span>
                </b-link>
              </td>
            </tr>
            <tr v-if="$can('read', 'usersendmessage')">
              <th class="pb-1">
                <feather-icon icon="MessageCircleIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t("Messenger Center")
                }}</span>
              </th>
              <td
                class="pb-1"
                style="display: flex; justify-content: flex-start; gap: 12px"
              >
                <b-link>
                  <span @click="showMessageUser()" class="text-success">
                    Message
                  </span>
                </b-link>
              </td>
            </tr>
          </table>
        </b-col>
        <b-col cols="12" xl="6">
          <table class="mt-2 w-100">
            <tr v-if="$can('view', 'balance')">
              <th class="pb-1">
                <feather-icon icon="DollarSignIcon" class="mr-75" />
                <span class="font-weight-bold">Balance</span>
              </th>
              <td v-if="userData.balances" class="pb-1">
                <span class="text-primary">
                  {{ numberFormat(userData.balances.value / 1000) }}
                </span>
              </td>
              <td v-else>
                <span class="text-primary">n/a</span>
              </td>
            </tr>
            <tr v-if="$can('view', 'balance')">
              <th class="pb-1">
                <feather-icon icon="DollarSignIcon" class="mr-75" />
                <span class="font-weight-bold">Outstanding Balance</span>
              </th>
              <td class="pb-1">
                <b-link
                  :key="totalOutstandingBalances"
                  class="text-primary"
                  v-if="userData.userData.user.txn_dtos"
                  @click="showModalOutstandingBalances()"
                >
                  {{
                    numberFormat(
                      sumArrayOutStandingBalance(
                        userData.userData.user.txn_dtos
                      )
                    )
                  }}
                </b-link>
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="ShieldIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Level") }}</span>
              </th>
              <td class="pb-1">
                {{ userLevelName || "n/a" }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="CalendarIcon" class="mr-75" />
                <span class="font-weight-bold">{{
                  $t("Registration date")
                }}</span>
              </th>
              <td class="pb-1">
                {{
                  formatDateTime(userData.userData.user.created_at).replace(
                    ".000000Z",
                    ""
                  )
                }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="DollarSignIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Currency") }}</span>
              </th>
              <td class="pb-1">
                {{
                  userData.userData.user.currency
                    ? userData.userData.user.currency
                    : "VND"
                }}
              </td>
            </tr>
            <tr>
              <th class="pb-1">
                <feather-icon icon="ArchiveIcon" class="mr-75" />
                <span class="font-weight-bold">{{ $t("Kyc") }}</span>
              </th>
              <td class="pb-1">
                <span v-if="userData.userData.user.is_kyc === 1" class="text-success">Đã KYC</span>
                <span v-if="userData.userData.user.is_kyc === 2" class="text-danger">Chưa KYC</span>
                <span v-if="userData.userData.user.is_kyc === 3" class="text-warning">Chờ duyệt</span>
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12">
        <users-edit :list-level="listLevel" :user-data="userData"/>
      </b-col>
    </b-row>

    <!-- Modal Update User Status -->

    <user-detail-change-status-modal
      :user-data="userData.userData.user"
      :status-update-info.sync="statusUpdateInfo"
      :status-update.sync="statusUpdate"
    />
    <user-message-modal
      :user-data="userData.userData.user"
      :title-message.sync="titleMessage"
    />
    <!-- :status-update-info.sync="statusUpdateInfo" -->
    <!-- Modal Outstanding Balances -->
    <user-view-outstanding-balances-modal />
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BLink,
  BOverlay,
} from "bootstrap-vue";
import { ref, onUnmounted, onUpdated, onMounted } from "@vue/composition-api";
import {
  avatarText,
  numberFormat,
  formatRemoveTime,
  formatDateTime,
  hiddenEmail,
  resolveCurrency,
} from "@core/utils/filter";
import UsersEdit from "@/views/apps/user/users-edit/UsersEdit.vue";
import UserDetailChangeStatusModal from "@/views/apps/user/users-detail/UserDetailChangeStatusModal.vue";
import UserMessageModal from "@/views/apps/user/users-detail/UserMessageModal.vue";
import UserViewOutstandingBalancesModal from "@/views/apps/user/users-view/UserViewOutstandingBalancesModal.vue";
import store from "@/store";
import router from "@/router";
import cashLogStoreModule from "@/views/apps/user/users-detail/cash-log/cashLogStoreModule";
import useUsersList from "../users-list/useUsersList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BLink,
    BOverlay,
    UsersEdit,
    UserDetailChangeStatusModal,
    UserViewOutstandingBalancesModal,
    UserMessageModal,
  },
  props: {
    userData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      statusUpdateInfo: -1,
      statusUpdate: false,
      userEmail: "",
      userEmail0: this.userData.userData.user.email,
      userEmailShow: "",
      userPhone: "",
      userPhone0: this.userData.userData.user.phone,
      userPhoneShow: "",
      loadingShowEmail: false,
      loadingShowPhone: false,
    };
  },
  /* watch: {
		totalOutstandingBalances: {
			deep: true,
			handler(newVal) {
				this.totalOutStandingBalances = newVal
			}
		}
	}, */
  mounted() {
    this.userEmail = this.userEmail0;
    this.userPhone = this.userPhone0;
  },
  methods: {
    showMessageUser() {
      this.$bvModal.show("modal-detail-message-user");
    },
    showModalUpdateStatus() {
      this.$bvModal.show("modal-update-status");
    },
    showModalOutstandingBalances() {
      this.$bvModal.show("modal-outstanding-balances");
    },
    async getUserEmail() {
      this.loadingShowEmail = true;
      if (!this.userEmailShow) {
        await this.$store
          .dispatch("app-user/fetchEmail", router.currentRoute.params.id)
          .then((response) => {
            this.userEmailShow = response.data.data;
          })
          .then(() => {
            setTimeout(() => {
              this.loadingShowEmail = false;
            }, 500);
          });
      } else {
        setTimeout(() => {
          this.loadingShowEmail = false;
        }, 500);
      }
    },
    async getUserPhone() {
      this.loadingShowPhone = true;
      if (!this.userPhoneShow) {
        await this.$store
          .dispatch("app-user/fetchPhone", router.currentRoute.params.id)
          .then((response) => {
            this.userPhoneShow = response.data.data;
          })
          .then(() => {
            setTimeout(() => {
              this.loadingShowPhone = false;
            }, 500);
          });
      } else {
        setTimeout(() => {
          this.loadingShowPhone = false;
        }, 500);
      }
    },
    async toCallCenter() {
      await store
        .dispatch("app-user/fetchCallCenter", {
          userId: router.currentRoute.params.id,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${response.data.message}`,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
        });
    },
    async toggleShow(id) {
      if (id == "email") {
        await this.getUserEmail();
        this.userEmail =
          this.userEmail == this.userEmail0
            ? this.userEmailShow
            : this.userEmail0;
      } else {
        await this.getUserPhone();
        this.userPhone =
          this.userPhone == this.userPhone0
            ? this.userPhoneShow
            : this.userPhone0;
      }
    },
    toggleIcon(id) {
      if (id == "email")
        return this.userEmail == this.userEmail0 ? "EyeIcon" : "EyeOffIcon";
      return this.userPhone == this.userPhone0 ? "EyeIcon" : "EyeOffIcon";
    },
  },
  setup(props) {
    const CASH_LOG_STORE_MODULE_NAME = "app-user-cash-log";
    const userLevelName = ref(null);
    const titleMessage = ref([]);
    const listLevel = ref([]);

    // Register module
    if (!store.hasModule(CASH_LOG_STORE_MODULE_NAME))
      store.registerModule(CASH_LOG_STORE_MODULE_NAME, cashLogStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(CASH_LOG_STORE_MODULE_NAME)) store.unregisterModule(CASH_LOG_STORE_MODULE_NAME)
    });

    const { resolveUserStatus } = useUsersList();
    //
    // const resolveUserProvince = (provinceLists, provinceId) => {
    //   const data = provinceLists.find((e) => e.id === provinceId);
    //   return data.name;
    // };
    const totalOutstandingBalances = ref(0);
    onMounted(() => {
      store
        .dispatch(
          "app-user-cash-log/fetchTotalOutStandingBalance",
          router.currentRoute.params.id
        )
        .then((response) => {
          totalOutstandingBalances.value = response;
        });
    });

    store.dispatch("app-user/fetchLevel").then((response) => {
      listLevel.value = response.data.data;
      userLevelName.value = response.data.data.find(
        (e) => e.id === props.userData.userData.user.level_id
      ).name;
      if (userLevelName.value) {
        userLevelName.value = userLevelName.value + "/Vip " + props.userData.userData.user.rank_id
      }
    });
    // store.dispatch("app-user-cash-log/fetchTitleMessage").then((response) => {
    //   console.log(123, response);
    //   titleMessage.value = response.data.data.map((val) => {
    //     return {
    //       label: val.title,
    //       value: val.id,
    //     };
    //   });
    // });

    const sumArrayOutStandingBalance = (array) => {
      let sum = 0;
      let newArray = array.map(function (val) {
        return Number(val.amount);
      });
      newArray.map(function (value) {
        sum += value;
      });
      return sum / 1000;
    };

    return {
      avatarText,
      numberFormat,
      resolveUserStatus,
      // resolveUserProvince,

      formatRemoveTime,
      formatDateTime,
      hiddenEmail,
      resolveCurrency,
      totalOutstandingBalances,

      userLevelName,
      sumArrayOutStandingBalance,
      titleMessage,
      listLevel
    };
  },
};
</script>

<style></style>
