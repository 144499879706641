import BankAccountService from '@/libs/bank-account.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchBankAccounts(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				BankAccountService.getBankAccountLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		fetchBalanceAccounts(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				BankAccountService.getBalanceAccountLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
		viewBankAccount(ctx, id) {
			return new Promise((resolve, reject) => {
				BankAccountService.viewBankAccount(id)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
    }
}
