<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs v-if="userData.userData" content-class="mt-2" pills>
      <!-- Tab: Member Information -->
      <b-tab active>
        <template #title>
          <span>{{ $t("Member information") }}</span>
        </template>
        <b-tabs vertical nav-wrapper-class="nav-vertical">
          <b-tab active :title="$t('Basic settings')" lazy>
            <user-edit-tab-account
              :user-data="userData.userData.user"
              :level-lists="listLevel"
              class="pt-75"
            />
          </b-tab>
          <b-tab
            :title="$t('Advance settings')"
            v-if="$can('edit', 'useradvance')"
            lazy
          >
            <user-edit-tab-advance
              :user-data="userData.userData.user"
              :level-lists="listLevel"
              class="pt-75"
            />
          </b-tab>
          <b-tab :title="$t('Security settings')" lazy>
            <user-detail-tab-information-security
              :user-data="userData.userData.user"
              class="pt-75"
            />
          </b-tab>

          <b-tab :title="$t('Payment account')" lazy>
            <user-detail-tab-information-payment-account class="pt-75" />
          </b-tab>

          <b-tab :title="$t('Balance account')" lazy>
            <user-detail-tab-information-balance-account class="pt-75" />
          </b-tab>

          <b-tab :title="$t('Outstanding')" v-if="$can('read', 'useroutstanding')" lazy>
            <user-detail-tab-outstanding class="pt-75" />
          </b-tab>
        </b-tabs>
      </b-tab>

      <!-- Tab: Account Tag -->
      <b-tab>
        <template #title>
          <span>{{ $t("Account tag") }}</span>
        </template>
        <account-tag-list :user-data="userData.userData.user" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Deposit -->
      <b-tab v-if="$can('view', 'deposit')" lazy>
        <template #title>
          <span>{{ $t("Deposit") }}</span>
        </template>
        <user-detail-tab-deposit :user-data="userData.userData.user" :status-options="statusOptions" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Withdrawal -->
      <b-tab v-if="$can('view', 'withdrawal')" lazy>
        <template #title>
          <span>{{ $t("Withdrawal") }}</span>
        </template>
        <user-detail-tab-withdrawal :user-data="userData.userData.user" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Adjustment -->
      <b-tab v-if="$can('view', 'adjustbalance')" lazy>
        <template #title>
          <span>{{ $t("Adjustment") }}</span>
        </template>
        <user-detail-tab-adjustment :user-data="userData.userData.user" :status-options="statusOptions" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Transfer -->
      <b-tab v-if="$can('view', 'transfer')" lazy>
        <template #title>
          <span>{{ $t("transfers") }}</span>
        </template>
        <user-detail-tab-transfer :user-data="userData.userData.user" :balances-list="userData.balancesList" :status-options="statusOptions" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Account Statement -->
      <b-tab v-if="$can('view', 'userstatement')" lazy>
        <template #title>
          <span>{{ $t("Account Statement") }}</span>
        </template>
        <user-detail-tab-account-statement
          :user-data="userData.userData.user"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Audit Log -->
      <!-- 12312 -->
      <b-tab v-if="$can('view', 'useraudit')" lazy>
        <template #title>
          <span>{{ $t("Audit logs") }}</span>
        </template>
        <user-detail-tab-audit-log :login-logs="userData.loginLogs" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <b-tab lazy>
        <template #title>
          <span>{{ $t("Cash logs") }}</span>
        </template>
        <user-detail-tab-cash-log class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Social -->
      <!-- <b-tab disabled>
        <template #title>
          <span>{{ $t("Member Agent Lock") }}</span>
        </template>
        <user-edit-tab-social class="mt-2 pt-75" />
      </b-tab> -->

      <!-- Tab: history -->
      <b-tab lazy>
        <template #title>
          <span>{{ $t("User Login History") }}</span>
        </template>
        <user-edit-tab-history :user-data="userData.userData.user" :status-options="statusOptions" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Bet history -->
      <b-tab v-if="$can('read', 'userbethistory')" lazy>
        <template #title>
          <span>{{ $t("Bet History") }}</span>
        </template>
        <user-detail-tab-bet-history class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: call history -->
      <b-tab v-if="$can('read', 'usercallhistory')" lazy>
        <template #title>
          <span>{{ $t("Call History") }}</span>
        </template>
        <user-detail-tab-call-history class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: call usersendmessage -->
      <b-tab v-if="$can('view', 'usersendmessage')" lazy>
        <template #title>
          <span>{{ $t("Message History") }}</span>
        </template>
        <user-detail-tab-message-history class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: User Bonus Request -->
      <b-tab v-if="$can('view', 'bonusrequest')" lazy>
        <template #title>
          <span>{{ $t("User bonus request") }}</span>
        </template>
        <user-detail-bonus-request :user-data="userData.userData.user" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Log kyc -->
      <b-tab v-if="$can('view', 'userkyc')" lazy>
        <template #title>
          <span>{{ $t("Log Kyc") }}</span>
        </template>
        <user-detail-tab-log-kyc :user-data="userData.userData.user" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import {ref, onUnmounted, onMounted} from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule";
import UserEditTabAccount from "./UserEditTabAccount.vue";
import UserEditTabInformation from "./UserEditTabInformation.vue";
import UserEditTabSocial from "./UserEditTabSocial.vue";
import UserEditTabHistory from "./UserEditTabHistory.vue";
import UserEditTabAdvance from "./UserEditTabAdvance.vue";

import UserDetailTabBetHistory from "@/views/apps/user/users-detail/UserDetailTabBetHistory.vue";
import UserDetailTabCallHistory from "@/views/apps/user/users-detail/UserDetailTabCallHistory.vue";
import UserDetailTabMessageHistory from "@/views/apps/user/users-detail/UserDetailTabMessageHistory.vue";
import UserDetailTabInformationSecurity from "@/views/apps/user/users-detail/UserDetailTabInformationSecurity.vue";
import UserDetailTabInformationPaymentAccount from "@/views/apps/user/users-detail/UserDetailTabInformationPaymentAccount.vue";
import UserDetailTabInformationBalanceAccount from "@/views/apps/user/users-detail/UserDetailTabInformationBalanceAccount.vue";
import UserDetailTabWithdrawal from "@/views/apps/user/users-detail/UserDetailTabWithdrawal.vue";
// import UserDetailTabDeposit from '@/views/apps/user/users-detail/UserDetailTabDeposit_.vue'
import UserDetailTabDeposit from "@/views/apps/user/users-detail/UserDetailTabDeposit.vue";
import UserDetailTabAdjustment from "@/views/apps/user/users-detail/UserDetailTabAdjustment.vue";
import UserDetailTabTransfer from "@/views/apps/user/users-detail/UserDetailTabTransfer.vue";
import UserDetailTabAuditLog from "@/views/apps/user/users-detail/UserDetailTabAuditLog.vue";
import UserDetailTabCashLog from "@/views/apps/user/users-detail/UserDetailTabCashLog.vue";
import roleStoreModule from "@/views/local-setting/local/roleStoreModule";
import UserDetailTabAccountStatement from "@/views/apps/user/users-detail/UserDetailTabAccountStatement.vue";
import AccountTagList from "@/views/apps/user/account-tag/AccountTagList.vue";
import UserDetailBonusRequest from "@/views/apps/user/users-detail/UserDetailBonusRequest";
import UserDetailTabLogKyc from "@/views/apps/user/users-detail/UserDetailTabLogKyc";
import UserDetailTabOutstanding from "@/views/apps/user/users-detail/UserDetailTabOutstanding";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserEditTabHistory,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
    UserDetailTabInformationSecurity,
    UserDetailTabInformationPaymentAccount,
    UserDetailTabWithdrawal,
    UserDetailTabDeposit,
    UserDetailTabAdjustment,
    UserDetailTabTransfer,
    UserDetailTabOutstanding,
    UserDetailTabAuditLog,
    UserDetailTabCashLog,
    UserDetailTabAccountStatement,
    AccountTagList,
    UserDetailTabInformationBalanceAccount,
    UserDetailTabBetHistory,
    UserDetailTabCallHistory,
    UserEditTabAdvance,
    UserDetailTabMessageHistory,
    UserDetailBonusRequest,
    UserDetailTabLogKyc
  },
  props: {
    listLevel: {
      type: Array,
      required: [],
    },
    userData: {
      type: Object,
      required: true,
      default: null,
    },
  },
  setup() {
    // const userData = ref(null);
    // const loginLogs = ref(null);
    const statusOptions = ref([]);
    const roleOptions = ref([]);
    const levelLists = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-user";
    const LOCAL_SETTING_ROLE_STORE_MODULE_NAME = "local-setting-role";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    if (!store.hasModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME))
      store.registerModule(
        LOCAL_SETTING_ROLE_STORE_MODULE_NAME,
        roleStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME))
        store.unregisterModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME);
    });

    store
        .dispatch("app-user/fetchUserAdjustmentStatusOptions")
        .then((response) => {
          statusOptions.value = response.data.data.map((val) => {
            return {
              label: val.name,
              value: val.id,
            };
          });
        });

    // store
    //   .dispatch("app-user/fetchUserDetail", router.currentRoute.params.id)
    //   .then((response) => {
    //     console.log('2')
    //     userData.value = response.data.data.user;
    //     loginLogs.value = response.data.data.loginLogs;
    //   });

    // store.dispatch("local-setting-role/fetchRoles").then((response) => {
    //   roleOptions.value = response.data;
    // });

    // onMounted(async () => {
    //   await store.dispatch("app-user/fetchLevel").then((response) => {
    //     levelLists.value = response.data.data;
    //   })
    // });

    return {
      // userData,
      roleOptions,
      levelLists,
      statusOptions,
      // loginLogs,
    };
  },
};
</script>

<style></style>
