import { ref, watch, computed } from '@vue/composition-api'
import { title, paginateArray, sortCompare } from '@core/utils/filter'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n'

export default function useBankAccountsList() {
	// Use toast
	const toast = useToast()

	const refBankAccountListTable = ref(null)

	// Table Handlers
	const tableColumns = [
		// { key: 'paymentType', label: i18n.t('Payment type') },
		{ key: 'bank_account', label: i18n.t('Account number') },
		{ key: 'fullName', label: i18n.t('Account name') },
		{ key: 'bank_name', label: i18n.t('Bank') },
		{ key: 'status', label: i18n.t('Trạng thái') },
		{ key: 'created_at', label: i18n.t('Created at') },
		// { key: 'stat', label: i18n.t('Status') },
	]
	const perPage = ref(25)
	const totalBankAccounts = ref(0)
	const currentPage = ref(1)
	const perPageOptions = [10, 25, 50, 100]
	const searchQuery = ref('')
	const sortBy = ref('id')
	const isSortDirDesc = ref(true)
	const usernameFilter = ref(null)
	const userIdFilter = ref(router.currentRoute.params.id)
	const emailFilter = ref(null)
	const fullnameFilter = ref(null)
	const phoneFilter = ref(null)
	const bankaccountFilter = ref(null)
	const statusFilter = ref(null)
	const bankIdFilter = ref(null)
	const bankOptions = ref(null)
	const statusBankFilter = ref(null)

	const dataMeta = computed(() => {
		const localItemsCount = refBankAccountListTable.value ? refBankAccountListTable.value.localItems.length : 0
		return {
			from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
			to: perPage.value * (currentPage.value - 1) + localItemsCount,
			of: totalBankAccounts.value,
		}
	})

	const refetchData = () => {
		refBankAccountListTable.value.refresh()
	}

	watch([currentPage, searchQuery, usernameFilter, phoneFilter, emailFilter, userIdFilter, bankaccountFilter, bankIdFilter, statusFilter], () => {
		refetchData()
	})

	store.dispatch('local-setting-bank/fetchBanks', {
		status: statusBankFilter.value,
	})
		.then(response => {
			bankOptions.value = response
		})

	const fetchBankAccounts = (ctx, callback) => {
		store
			.dispatch('payment-bank-account/fetchBankAccounts', {
				q: searchQuery.value,
				perPage: perPage.value,
				page: currentPage.value,
				sortBy: sortBy.value,
				sortDesc: isSortDirDesc.value,
				username: usernameFilter.value,
				email: emailFilter.value,
				phone: phoneFilter.value,
				userId: userIdFilter.value,
				bankAccount: bankaccountFilter.value,
				bankId: bankIdFilter.value,
				stat: statusFilter.value,
			})
			.then(response => {

				const { bankAccounts, total } = response
				callback(bankAccounts)
				totalBankAccounts.value = total
			})
			.catch(() => {
				/* toast({
					component: ToastificationContent,
					props: {
						title: 'Error fetching bank accounts list',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				}) */
			})
	}

	// *===============================================---*
	// *--------- UI ---------------------------------------*
	// *===============================================---*

	const resolveUserBankAccountStatus = status => {
		if (status === 1) return {label: i18n.t('Active'), icon: 'CheckCircleIcon', variant: 'success' }
		if (status === 2) return {label: i18n.t('Inactive'), icon: 'XCircleIcon', variant: 'danger' }
		if (status === 3) return {label: i18n.t('Non-Verification'), icon: 'XIcon', variant: 'warning' }
		return {label: 'None', icon: 'TriangleIcon', variant: 'secondary' }
	}

	return {
		fetchBankAccounts,
		bankOptions,
		tableColumns,
		perPage,
		currentPage,
		totalBankAccounts,
		dataMeta,
		perPageOptions,
		searchQuery,
		sortBy,
		isSortDirDesc,
		refBankAccountListTable,

		resolveUserBankAccountStatus,
		refetchData,

		// Extra Filters
		usernameFilter,
		userIdFilter,
		emailFilter,
		fullnameFilter,
		phoneFilter,
		bankaccountFilter,
		statusFilter,
		bankIdFilter,
	}
}
