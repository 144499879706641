/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <!-- Filters -->
    <!-- <payment-accounts-list-filters
			v-if="bankOptions"
			:username-filter.sync="usernameFilter"
			:user-id-filter.sync="userIdFilter"
			:fullname-filter.sync="fullnameFilter"
			:email-filter.sync="emailFilter"
			:phone-filter.sync="phoneFilter"
			:bankaccount-filter.sync="bankaccountFilter"
			:province-id-filter.sync="provinceIdFilter"
			:status-filter.sync="statusFilter"
			:bank-id-filter.sync="bankIdFilter"
			:status-options="statusOptions"
			:bank-options.sync="bankOptions"
		/> -->
    <b-card v-if="$can('view', 'userbankaccount')" no-body class="mb-0">
      <b-table
        ref="refBankAccountListTable"
        class="position-relative table-white-space"
        :items="fetchBankAccounts"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(bank_account)="data">
          <b-overlay
              :show="loadingShowAccount"
              variant="transparent"
              no-wrap
          />
          {{ data.item.bank_account }}
          <span
              class="cursor-pointer"
              v-if="$can('view', 'bankaccount')"
              @click.prevent="toggleShow(data.item)"
          >
                  <feather-icon :icon="toggleIcon(data.item)" />
                </span>
        </template>

        <template #cell(fullName)="data">
          {{ data.item.user.name }}
        </template>

        <template #cell(paymentType)="data">
          {{ data.item.paymentType ? data.item.paymentType : "n/a" }}
        </template>

        <template #cell(bank)="data">
          {{ data.item.bankName }}
        </template>

        <template #cell(status)="data">
					<b-badge
						pill
						:variant="`light-${resolveUserBankAccountStatus(data.item.stat).variant}`"
						class="text-capitalize"
					>
						<feather-icon :icon="resolveUserBankAccountStatus(data.item.stat).icon" />
						{{ resolveUserBankAccountStatus(data.item.stat).label }}
					</b-badge>
				</template>

        <template #cell(created_at)="data">
          {{ data.item.created_at }}
        </template>

        <template #cell(stat)="data">
          <!-- <b-badge
						pill
						:variant="`light-${resolveUserBankAccountStatus(data.item.status).variant}`"
						class="text-capitalize"
					>
						<feather-icon :icon="resolveUserBankAccountStatus(data.item.status).icon" />
						{{ resolveUserBankAccountStatus(data.item.status).label }}
					</b-badge> -->
          <!-- secondary -->
          <div>
            <b-form-checkbox
              :checked="data.item.stat == 1 ? true : false"
              class="custom-control-primary"
              name="check-button"
              switch
              inline
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBankAccounts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card v-else class="text-center text-danger">
      No permission to view
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox, BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { formatDateTime } from "@core/utils/filter";
import paymentAccountStoreModule from "@/views/payments/bank-account/bankAccountStoreModule";
import bankStoreModule from "@/views/local-setting/local/bankStoreModule";
// import PaymentAccountsListFilters from './payment-account/PaymentAccountsListFilters.vue'
import usePaymentAccountsList from "./payment-account/usePaymentAccountsList";
import router from "@/router";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BOverlay,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      loadingShowAccount: false,
    };
  },
  setup() {
    const PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME = "payment-bank-account";
    const LOCAL_SETTING_BANK_STORE_MODULE_NAME = "local-setting-bank";

    // Register module
    if (!store.hasModule(PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME))
      store.registerModule(
        PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME,
        paymentAccountStoreModule
      );
    if (!store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME))
      store.registerModule(
        LOCAL_SETTING_BANK_STORE_MODULE_NAME,
        bankStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME))
        store.unregisterModule(PAYMENT_BANK_ACCOUNT_STORE_MODULE_NAME);
      if (store.hasModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME))
        store.unregisterModule(LOCAL_SETTING_BANK_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "active", value: "1" },
      { label: "deactive", value: "2" },
      { label: "non-verification", value: "3" },
    ];

    const {
      fetchBankAccounts,
      bankOptions,
      tableColumns,
      perPage,
      currentPage,
      totalBankAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBankAccountListTable,
      refetchData,

      // UI
      resolveUserBankAccountStatus,

      // Extra Filters
      usernameFilter,
      userIdFilter,
      emailFilter,
      fullnameFilter,
      phoneFilter,
      bankaccountFilter,
      provinceIdFilter,
      bankIdFilter,
      statusFilter,
    } = usePaymentAccountsList();

    return {
      fetchBankAccounts,
      bankOptions,
      tableColumns,
      perPage,
      currentPage,
      totalBankAccounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBankAccountListTable,
      refetchData,

      // UI
      resolveUserBankAccountStatus,

      statusOptions,

      // Extra Filters
      usernameFilter,
      userIdFilter,
      emailFilter,
      fullnameFilter,
      phoneFilter,
      bankaccountFilter,
      provinceIdFilter,
      bankIdFilter,
      statusFilter,

      formatDateTime,
    };
  },
  methods: {
    async getBankAccount(bank) {
      this.loadingShowAccount = true;
      if (!bank.bank_account_show) {
        bank.bank_account_hide = bank.bank_account;
        await this.$store
            .dispatch("payment-bank-account/viewBankAccount", bank.id)
            .then((response) => {
              bank.bank_account_show = response.data.data;
            })
            .then(() => {
              setTimeout(() => {
                this.loadingShowAccount = false;
              }, 500);
            });
      } else {
        setTimeout(() => {
          this.loadingShowAccount = false;
        }, 500);
      }
    },
    async toggleShow(bank) {
      await this.getBankAccount(bank);
      if (bank.bank_account === bank.bank_account_show) {
        bank.bank_account = bank.bank_account_hide;
      } else if (bank.bank_account === bank.bank_account_hide || !bank.bank_account_hide) {
        bank.bank_account = bank.bank_account_show;
      }
    },
    toggleIcon(bank) {
      return (!bank.bank_account_hide || bank.bank_account === bank.bank_account_hide) ? "EyeOffIcon" : "EyeIcon";
    },
  }
};
</script>
